
export default {
    head(){
        let i18nHead = null
        try {
          i18nHead = this.$nuxtI18nHead({ addSeoAttributes: {canonicalQueries: ['vhistype', 'date_period[]']} });
        } catch (error) {
          i18nHead = {};
          i18nHead.htmlAttrs = {};
          i18nHead.meta = {};
          i18nHead.link = {};
        }
        return {
          titleTemplate: '%s | 10Life',
          title: "HK's Leading Insurance Comparison Platform",
          htmlAttrs: {
            ...i18nHead.htmlAttrs
          },
          meta: [
            { charset: 'utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { hid: 'description', name: 'description', content: '10Life has analysed >1000 insurance products to help users compare and select the right product. Users can also speak directly to our advisors for help.' },
            { name: 'format-detection', content: 'telephone=no' },
            {
              hid: 'og:title', name: 'og:title',
              content: "10Life"
            },
            {
              hid: 'og:description', name: 'og:description',
              content: '10Life has analysed >1000 insurance products to help users compare and select the right product. Users can also speak directly to our advisors for help.'
            },
            {
              hid: 'og:image', name: 'og:image',
              content: "/10life-logo.png" || ''
            },
            { property: 'og:site_name', content: '10Life' },
            { property: 'og:locale', content: 'zh_HK' },
            ...i18nHead.meta
          ],
          link: [
            { rel: 'icon', type: 'image/x-icon', href: '/favicon.png' },
            ...i18nHead.link
          ],
          script: [
            {
              src: '/js/fb-sdk.js',
              async: true,
              defer: true,
            },
            // {
            //   src: 'https://accounts.google.com/gsi/client',
            //   async: true,
            //   defer: true,
            // },
            {
              src: 'https://www.googletagmanager.com/gtag/js?id=UA-85809925-1',
              async: true,
              defer: true,
            },
            {
              src: '/js/analytics-campaign.js',
              async: true,
              defer: true,
            },
            {
              src: '/js/analytics-script.js',
              async: true,
              defer: true,
            },
          ]
        }
      },
  };